<template>
    <el-dialog title="编辑" :visible="visible" width="60%" @close="close" :close-on-click-modal="false">
        <el-row>
            <el-form ref="form" :model="form" label-width="120px" style="max-height: 50vh;overflow: auto;padding: 20px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="二级单位" prop="EJDW"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.EJDW"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="三级单位" prop="SJDW"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.SJDW"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="XM"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.XM"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号" prop="SFZH"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.SFZH"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="性别" prop="XB"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.XB"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="年龄" prop="NL"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.NL"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="LXDH"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.LXDH"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工种" prop="GZ"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.GZ"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="总工龄" prop="ZGL"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.ZGL"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="职业工龄" prop="ZYGL"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.ZYGL"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="接害因素">
                            <el-input v-model="form.JHYS" type="textarea" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input v-model="form.BZ" type="textarea" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否在岗">
                            <el-switch v-model="form.SFZG"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button style="float:left" v-if="form.id" @click="handleDelete" type="danger" :loading="loading"
                :disabled="loading">删 除</el-button>
            <el-button @click="close">取 消</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async handleDelete() {

        },
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(() => { return false });
            if (!valid) return
            try {
                const resp = await this.$http.post('/industrial/data', this.form)
                if (resp.data.status == 'success') {
                    this.$message[resp.data.status](resp.data.msg)
                    this.$emit('close')
                    this.$emit('update')
                } else {
                    this.$alert(resp.data.msg, '出错了', { type: 'error' })
                }
            } catch (error) {
                this.$alert(error.message, '出错了', { type: 'error' })
            }
        },
        close() {
            this.$emit("close")
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
